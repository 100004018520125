import axios from 'axios';

const state = {
    pageContent: null,
    pageContentStatus: false,
}

const getters = {
    // دریافت وضعیت گرفتن محتویات صفحه
    getPageContentStatus(state) {
        return state.pageContentStatus;
    },
    //    گرفتن محتویات صفحه
    getPageContent(state) {
        return state.pageContent;
    }
}

const mutations = {
    // تنظیم کردن وضعیت دریافت محتویات صفحه
    setPageContentStatus(state, status) {
        state.pageContentStatus = status;
    },
    //   تنظیم محتویات صفحه
    setPageContent(state, content) {
        state.pageContent = content;
    }
}

const actions = {
    // گرفتن محتویات صفحه از سرور
    async getPageFromServer({ commit }, page) {
        commit("setPageContentStatus", true);
        await axios.get("/front/pages/" + page).then(res => {
            commit("setPageContentStatus", false);
            commit("setPageContent", res.data.data.page);

        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}