import $ from "jquery";
import Vue from "vue";

// غیر فعال کردن اسلایدر وقتی موس بره روش
$(document).ready(function () {
    $(".swiper-container").hover(function() {
        (this).swiper.autoplay.stop();
    }, function() {
        (this).swiper.autoplay.start();
    })
});

window.clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

Vue.prototype.console = {
    log : (value) => {
        console.log(value)
    }
}