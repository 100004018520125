import Vue from "vue";
import moment from 'moment-jalaali'

Vue.filter('price' , function(num) {
    if(num) {
        // let englishNumber = this.convertToEnglishNumber(num);
        const result = num.toString()
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return result;
    } else {
        return 0
    }
})

Vue.filter('persianDate' , function(date) {
    return moment(date).format('HH:mm jYYYY/jMM/jDD ')
})
Vue.filter('Date' , function(date) {
    return moment(date).format('jYYYY/jMM/jDD ')
})

Vue.filter('date', function(date) {
    var now = new Date();
    let start = moment(date); // some random moment in time (in ms)
    let end = moment(now); // some random moment after start (in ms)
    let diff = end.diff(start);
    let time = moment.utc(diff).format("H:m:ss");
    let dayDiff = diff / (1000 * 60 * 60 * 24);
    let hour = time.split(":")[0];
    let min = time.split(":")[1];
    let second = time.split(":")[2];
    if ((hour == 0 && min == 0 && second <= 59 && dayDiff <= 1) || diff < 0) {
      return " چند ثانیه پیش";
    } else if (hour == 0 && min <= 59 && dayDiff <= 1) {
      return min + " دقیقه پیش";
    } else if (hour >= 1 && 24 >= hour && dayDiff <= 1) {
      return "حدود " + hour + " ساعت پیش";
    } else if (dayDiff > 1) {
      return moment(date).format("dddd jDD jMMMM jYYYY");
    }
})
