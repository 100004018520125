import axios from 'axios';

const state = {
    provincesData: null,
    isRequestingProvinces: false,
}

const getters = {
    getProvincesData(state) {
        return state.provincesData
    }
}

const mutations = {
    setProvincesData(state, data) {
        state.provincesData = data
    }
}

const actions = {
    async getProvincesDataFromServer({commit, state}) {
        if (!state.isRequestingProvinces) {
            state.isRequestingProvinces = true;
            await axios.get('front/area').then(response => {
                commit("setProvincesData", response.data.data.provinces)
            })
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
