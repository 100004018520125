/* eslint-disable no-console */


import { register } from 'register-service-worker'

console.log(process.env.NODE_ENV)


register(`${process.env.BASE_URL}service-worker.js`, {

  ready() {
    console.log(
        'App is being served from cache by a service worker.'
    )
  },
  registered() {

    let installPromptEvent;
    const addBtn = document.querySelector('.banner');
    const addBtn2=document.querySelectorAll('banner-menu')

    window.addEventListener('beforeinstallprompt', (e) => {

      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      installPromptEvent = e;

      // Update UI to notify the user they can add to home screen
    });

    // addBtn.addEventListener('click', (e) => {
    //     //   console.log("BANNER CLICK")
    //     //   console.log(installPromptEvent)
    //     //   e.preventDefault()
    //     //   if (installPromptEvent) {
    //     //     installPromptEvent.prompt();
    //     //     installPromptEvent.userchoice
    //     //         .then(choiceResult => {
    //     //           if (choiceResult.outcome === 'accepted') {
    //     //             console.log('UserAccepted')
    //     //           } else {
    //     //             console.log('UserDismis')
    //     //           }
    //     //           installPromptEvent = null;
    //     //         })
    //     //
    //     //   }
    //     // });
    //     // addBtn2.addEventListener('click', (e) => {
    //     //   console.log("BANNER CLICK")
    //     //   console.log(installPromptEvent)
    //     //   e.preventDefault()
    //     //   if (installPromptEvent) {
    //     //     installPromptEvent.prompt();
    //     //     installPromptEvent.userchoice
    //     //         .then(choiceResult => {
    //     //           if (choiceResult.outcome === 'accepted') {
    //     //             console.log('UserAccepted')
    //     //           } else {
    //     //             console.log('UserDismis')
    //     //           }
    //     //           installPromptEvent = null;
    //     //         })
    //     //
    //     //   }
    //     // });

    console.log('Service worker has been registered.')
  },
  cached(event) {
    console.log('Content has been cached for offline use.')
    console.log(event)
  },
  updatefound() {
    console.log('New content is downloading.')
  },
  updated() {
    console.log('New content is available; please refresh.');
    // console.log(caches.keys())
    // caches.keys().then(function(names) {
    //   for (let name of names) caches.delete(name);
    // });
    alert('در حال بروزرسانی...')
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister()
        }
      })
      window.location.reload(true)
    }

  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  }

})

self.addEventListener('fetch', () => {})
