import axios from "axios";

const state = {
    faqList: null,
    faqStatus: false,
};
const getters = {
    // گرفتن لیست پرسش های متداول
    getFaqList(state) {
        return state.faqList;
    },
    // گرفتن استاتوس وضعیت برای نمایش اسکلتون
    getFaqStatus(state) {
        return state.faqStatus;
    }
};
const mutations = {
    // ست کردن لیست پرسش های متداول
    setFaqList(state, list) {
        state.faqList = list;
    },
    //    ست کردن استاتوس پرسش های متداول
    setFaqStatus(state, status) {
        state.faqStatus = status
    }
};
const actions = {
    // دریافت لیست پرسش های متداول از سرور
    async getAllFaqListFromServer({ commit }) {
        commit("setFaqStatus", true);

        await axios.get("/front/f_a_qs").then(response => {
            commit("setFaqList", response.data.data.fAQs.data)
            commit("setFaqStatus", false);
        })
    }
};
export default {
    state,
    getters,
    mutations,
    actions
}