import routes from '@@/core/routes'
// let new_routes = [
    // {
    //     path: '/admin/gift_package',
    //     component: () =>
    //          import(/* webpackChunkName: "admin" */ '@@/core/pages/GiftPackage/GiftPackage'),
    //     meta: {
    //         title: 'بسته بندی هدیه'
    //     }
    // },
    // {
    //     path: '/admin/store/transaction-management',
    //     component: () =>
    //          import(/* webpackChunkName: "admin" */ '@@/core/pages/Store/TransactionManagement/TransactionManagement'),
    //     meta: {
    //         title: 'تعیین وضعیت انبار'
    //     }
    // }
// ]
// routes[0].children.push(...new_routes)
export default routes;
