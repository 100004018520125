import Vue from "vue";

Vue.directive('price', {
    twoWay: true, // this transformation applies back to the vm
    bind: function () {
        this.handler = function () {
            this.set(this.el.value.replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }.bind(this);
        this.el.addEventListener('input', this.handler);
    },
    unbind: function () {
        this.el.removeEventListener('input', this.handler);
    }
});