export default [{
        routerName: 'blogDetail',
        routerParams: 'id',
        routerQuery: '',
        routerSlug: 'slug',
        listRouterName: 'weblog.list',
        linkable_type: 'Modules\\Blog\\Entities\\Post',
    },
    {
        routerName: 'product.category',
        routerParams: 'category',
        routerQuery: '',
        routerSlug: 'slug',
        listRouterName: '',
        linkable_type: "Modules\\Category\\Entities\\Category",
    },
    {
        routerName: 'product.detail',
        routerParams: 'id',
        routerQuery: '',
        routerSlug: 'slug',
        listRouterName: 'products',
        linkable_type: "Modules\\Product\\Entities\\Product",
    },
    {
        routerName: 'products',
        routerParams: '',
        routerQuery: 'flash_id',
        routerSlug: '',
        listRouterName: '',
        linkable_type: 'Modules\\Flash\\Entities\\Flash',
    },
    {
        routerName: 'page',
        routerParams: 'page',
        routerQuery: '',
        routerSlug: 'slug',
        listRouterName: '',
        linkable_type: 'Modules\\Page\\Entities\\Page',
    },
    {
        routerName: 'about-us',
        routerParams: '',
        routerQuery: '',
        routerSlug: '',
        listRouterName: 'about-us',
        linkable_type: 'Custom\\AboutUs',
    },
    {
        routerName: 'contact',
        routerParams: '',
        routerQuery: '',
        routerSlug: '',
        listRouterName: 'contact',
        linkable_type: 'Custom\\ContactUs',
    },
]