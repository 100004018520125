export default {
  data() {
    return {
      disabledSliders: []
    }
  },
  methods: {
    initSwiper(name, kill = false, callback) {
      let action = () => {
        if (kill) {
          this.disabledSliders.push(name)
        }
        setTimeout(() => {
          if (kill) {
            this.disabledSliders = this.disabledSliders.filter(item => item != name)
          }
          setTimeout(() => {
            callback()
          }, 0)
        }, 0)
      }
      if (window.innerWidth < 768) {
        setTimeout(() => {
          action()
        }, 800)
      } else {
        action()
      }
    },
    hasPermissions(permissions) {
      let passed = false;
      permissions.forEach(p => {
        if(p == 'public') {
          passed = true
        } else if(this.hasPermission(p)) {
          passed = true
        }
      });
      return passed
    },
    hasPermission(permission) {
      let permissions = this.$store.getters['admin/getPermissions'];
      if (!permissions) {
        return false;
      }
      return permissions.includes(permission);
    }
  }
}
